<!--
 * @Descripttion: 专家抽取 - 第二步：项目抽取
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 10:24:39
-->
<template>
    <div class="pad">
        <div class="pad flex-col whiteBg">
            <van-circle v-model="currentRate"
                speed="50"
                size="5rem"
                :rate="rate"
                stroke-width="60"
                :text="circleText"
                :color="resultColor"
                layer-color="#E1FFFB"
                class="extractionText"
                :class="{failText : isFail}"
            />
            <p class="pad">{{ textInfo }}</p>
            <p class="h75" v-show="!isFail"></p>
            <button class="fail whiteBg h75" v-show="isFail" @click="reLoad">重试</button>
        </div>
    </div>
</template>

<script>
import { Circle } from 'vant'
export default {

    data(){
        return{
            rate : 100,
            currentRate : 0,
            circleText : '正在抽取',
            textInfo : '智能抽取中，请耐心等待...',
            isFail : false,
            resultColor : '#00CCB2',
        }
    },

    components:{
        [Circle.name]:Circle
    },

    watch:{
        currentRate(tt){
            if(tt == this.rate) {
                if(Math.random() > 0.5){
                    this.circleText = '抽取成功'
                    this.textInfo = '已抽取成功，请稍等…'
                    this.isFail = false
                    this.resultColor = '#00CCB2',
                    setTimeout(() => {
                        this.$router.push('/step3')
                    }, 1000);
                }else{
                    this.circleText = '抽取失败'
                    this.textInfo = '您的抽取失败了，请稍候再试'
                    this.isFail = true
                    this.resultColor="#FF7A7A"
                }
            }
        }
    },
    beforeMount(){
        this.$emit('onActive',2)
    },

    methods:{
        reLoad(){
            window.location.reload()
        }
    }
}
</script>

<style scoped>
    .h75{height: 0.75rem;}
</style>